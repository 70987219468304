// import { motion } from 'framer-motion'
import styled from 'styled-components'
// import ScrollBar from 'react-smooth-scrollbar'

export const Container = styled.div`
  /* height: 100vh; */
  /* scroll-snap-type: y mandatory; */
  position: relative;
  /* pointer-events: none; */
  /* overflow: auto; */
`
