import React from 'react'
// import { motion, useTransform, useViewportScroll } from 'framer-motion'
import { Link } from 'react-router-dom'
import { Container, OverlayBody, StickyContainer, Subtitle, Title, ImageContainer, ModelWrapper } from './styles'
// import ServicesImg from '../../assets/images/Services.jpg'

// import SectionsContext from '../SectionsWrapper/SectionsContext'
// import useWrapperScroll from '../SectionsWrapper/useWrapperScroll'
import ButtonCircle from '../ButtonCircle'
import { forwardRef } from 'react'
import BoxModel from '../FirstSection/BoxModel'
import { useRef } from 'react'
import modelBomba from '../../assets/turbine/bomba.glb'

function SecondSection(props, ref) {
  const meshRef = useRef(null);

  return (
    <Container id="second" ref={ref}>
      <StickyContainer>
        <OverlayBody>
          <Title
            exit={{ opacity: 0 }}
          // style={{ opacity: opacityTitle }}
          >
            Nuestros Servicios
          </Title>
          <Subtitle exit={{ opacity: 0 }}>
            Explore todas las soluciones y servicios de software para Transformación digital, Gestión del Conocimiento, Digitalización de Procesos, Analítica y Ciencia de Datos y más para potenciar su fuerza de trabajo y superar el ritmo de la innovación.
          </Subtitle>
          <div className="button-circle-container">
            <Link to="/servicios/transformacion-digital">
              <ButtonCircle black canAnimate delay={0}>
                VER LOS SERVICIOS
              </ButtonCircle>
            </Link>
          </div>
        </OverlayBody>

        <ImageContainer>
          <ModelWrapper>
            <BoxModel
              ref={meshRef}
              pathModel={modelBomba}
              // meshProps={initialMeshValues}
              camera={{ fov: 25, position: [10, -20, 0] }}
              style={{ width: "100%", height: '100%' }}
              enabledZoomIn={false}
              enabledOrbitControls={true}
            />
          </ModelWrapper>
          <img
            alt=""
            src="https://res.cloudinary.com/tecsup-tasa/image/upload/v1618352338/landingpage/Dise%C3%B1o_sin_t%C3%ADtulo_3_-min_1_o4fdb3.png"
          />
        </ImageContainer>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"
          style={{
            width: '100%',
            height: '20vh',
            bottom: '-34px',
            zIndex: '100',
            position: 'absolute',
          }}>
          <polygon fill="#0E0E0F" points="0,100 100,0 100,100" />
        </svg>
      </StickyContainer>
    </Container>
  )
}


export default forwardRef(SecondSection);
