import React from 'react'
import {motion} from 'framer-motion'

function CienciaDatosSVG() {

  const icon = {
      hidden: {
          // opacity: 0,
          pathLength: 0,
          fill: "none",
      },
      visible: {
          // opacity: 1,  
          pathLength: 1,     
          fill: "none",
      }
  };
  const initial = "hidden";
  const animate = "visible";
  const transition = {
      duration: 2,
      ease: "easeInOut",
      // flip: Infinity,
  }

  return (
    <motion.svg 
      version="1.1" 
      xmlns="http://www.w3.org/2000/svg" 
      xlink="http://www.w3.org/1999/xlink" 
      x="0px" 
      y="0px"
      viewBox="0 0 512 512" 
      space="preserve"
      style={{strokeWidth: "15px"}}
    >
      <motion.path 
          d="M512,45c0-24.813-20.187-45-45-45H45C20.187,0,0,20.187,0,45v302c0,24.813,20.187,45,45,45h135v31.507
        c-34.191,6.969-60,37.274-60,73.493c0,8.284,6.716,15,15,15h242.115c0.013,0,0.026-0.002,0.039-0.002
        C451.523,511.914,512,451.388,512,377c0-6.466-0.467-12.823-1.35-19.049c0.894-3.568,1.35-7.235,1.35-10.951V45z M45,362
        c-8.271,0-15-6.729-15-15v-15.1h219.722c-3.388,9.555-5.729,19.701-6.884,30.1H45z M249.722,422H210v-30h32.838
        C243.993,402.399,246.333,412.445,249.722,422z M152.57,482c6.191-17.461,22.874-30,42.43-30h69.806
        c7.598,11.328,16.866,21.444,27.446,30H152.57z M377,482c-57.897,0-105-47.103-105-105c0-57.897,47.103-105,105-105
        c57.897,0,105,47.103,105,105C482,434.897,434.897,482,377,482z M482,292.251C457.231,261.624,419.367,242,377,242
        c-46.708,0-87.947,23.846-112.194,60H30V45c0-8.271,6.729-15,15-15h422c8.271,0,15,6.729,15,15V292.251z"
          variants={icon}
          initial={initial}
          animate={animate}
          transition={transition}
      />
      
      <motion.path 
          d="M232.62,107.107c-1.405-0.834,8.685,5.266-74.596-45.229c-4.767-2.89-10.743-2.898-15.516-0.022L67.44,107.072
        c-4.444,2.596-7.439,7.48-7.439,12.957v90.647c0,5.2,2.693,10.029,7.118,12.762l75,46.324c2.268,1.399,5.05,2.238,7.882,2.238
        c2.774,0,5.574-0.814,7.882-2.238l75-46.324c4.424-2.733,7.118-7.562,7.118-12.762v-90.647
        C240,111.065,232.717,107.207,232.62,107.107z M135,230.105l-45-27.794V146.62l45,27.194V230.105z M150,147.826l-45.963-27.776
        l46.185-27.818l45.812,27.776L150,147.826z M210,202.311l-45,27.794v-56.29l45-27.194V202.311z"
          variants={icon}
          initial={initial}
          animate={animate}
          transition={transition}
      />
      
      <motion.path 
          d="M407,91h-60c-8.284,0-15,6.716-15,15s6.716,15,15,15h60c8.284,0,15-6.716,15-15S415.284,91,407,91z" 
          variants={icon}
          initial={initial}
          animate={animate}
          transition={transition}
        />
      
      <motion.path 
          d="M407,151h-60c-8.284,0-15,6.716-15,15s6.716,15,15,15h60c8.284,0,15-6.716,15-15S415.284,151,407,151z" 
          variants={icon}
          initial={initial}
          animate={animate}
          transition={transition}
      />
      
      <motion.path 
          d="M377,332c-24.813,0-45,20.187-45,45c0,0.026,0.002,0.051,0.002,0.077v0.257c0,0.088,0.012,0.172,0.013,0.259
        C332.335,402.133,352.385,422,377,422c24.813,0,45-20.187,45-45S401.813,332,377,332z M377,392
        c-8.259,0-14.977-6.708-14.998-14.961V377c0-0.007-0.001-0.013-0.001-0.019C362.012,368.719,368.735,362,377,362
        c8.271,0,15,6.729,15,15S385.271,392,377,392z"
          variants={icon}  
          initial={initial}
          animate={animate}
          transition={transition}
      />
    </motion.svg>
  )
}

export default CienciaDatosSVG
