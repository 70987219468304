import styled from "styled-components";

const ContainerModal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0000004f;

  .window-modal {
    position: relative;
    background: #fff;
    borderradius: 5px;
    padding: 15px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    z-index: 10;
    min-width: 320px;
    max-width: 800px;
    max-height: 500px;
  }

  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export default ContainerModal;
