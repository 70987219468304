export const preguntas = [
  {
    idPregunta: 1,
    idAreaConocimiento: 1,
    contenido:
      '<p>Priorizamos las iniciativas digitales en función de su capacidad para afectar nuestros objetivos comerciales.</p>',
    nivel: 1,
    tipo: 1,
    alternativas: [
      {
        contenido: '<p>Totalmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 1,
        idPregunta: 1,
        valorAlternativa: 8,
      },
      {
        contenido: '<p>De acuerdo</p>',
        correcta: false,
        idAlternativa: 2,
        idPregunta: 1,
        valorAlternativa: 6,
      },
      {
        contenido: '<p>Parcialmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 3,
        idPregunta: 1,
        valorAlternativa: 4,
      },
      {
        contenido: '<p>Desacuerdo</p>',
        correcta: false,
        idAlternativa: 4,
        idPregunta: 1,
        valorAlternativa: 2,
      },
      {
        contenido: '<p>Muy en desacuerdo</p>',
        correcta: false,
        idAlternativa: 5,
        idPregunta: 1,
        valorAlternativa: 0,
      },
    ],
  },
  {
    idPregunta: 2,
    idAreaConocimiento: 1,
    contenido:
      '<p>La investigación y el pensamiento centrado en el cliente se integran en todo el diseño y desarrollo de productos y servicios digitales</p>',
    nivel: 1,
    tipo: 1,
    alternativas: [
      {
        contenido: '<p>Totalmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 6,
        idPregunta: 2,
        valorAlternativa: 8,
      },
      {
        contenido: '<p>De acuerdo</p>',
        correcta: false,
        idAlternativa: 7,
        idPregunta: 2,
        valorAlternativa: 6,
      },
      {
        contenido: '<p>Parcialmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 8,
        idPregunta: 2,
        valorAlternativa: 4,
      },
      {
        contenido: '<p>Desacuerdo</p>',
        correcta: false,
        idAlternativa: 9,
        idPregunta: 2,
        valorAlternativa: 2,
      },
      {
        contenido: '<p>Muy en desacuerdo</p>',
        correcta: false,
        idAlternativa: 10,
        idPregunta: 2,
        valorAlternativa: 0,
      },
    ],
  },
  {
    idPregunta: 3,
    idAreaConocimiento: 1,
    contenido:
      '<p>Brindamos a nuestros clientes una experiencia consistente en diferentes dispositivos y puntos de contacto</p>',
    nivel: 1,
    tipo: 1,
    alternativas: [
      {
        contenido: '<p>Totalmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 11,
        idPregunta: 3,
        valorAlternativa: 8,
      },
      {
        contenido: '<p>De acuerdo</p>',
        correcta: false,
        idAlternativa: 12,
        idPregunta: 3,
        valorAlternativa: 6,
      },
      {
        contenido: '<p>Parcialmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 13,
        idPregunta: 3,
        valorAlternativa: 4,
      },
      {
        contenido: '<p>Desacuerdo</p>',
        correcta: false,
        idAlternativa: 14,
        idPregunta: 3,
        valorAlternativa: 2,
      },
      {
        contenido: '<p>Muy en desacuerdo</p>',
        correcta: false,
        idAlternativa: 15,
        idPregunta: 3,
        valorAlternativa: 0,
      },
    ],
  },
  {
    idPregunta: 4,
    idAreaConocimiento: 2,
    contenido:
      '<p>Medimos constantemente el impacto de las iniciativas digitales frente a los objetivos comerciales y los indicadores clave de rendimiento.</p>',
    nivel: 1,
    tipo: 1,
    alternativas: [
      {
        contenido: '<p>Totalmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 16,
        idPregunta: 4,
        valorAlternativa: 6,
      },
      {
        contenido: '<p>De acuerdo</p>',
        correcta: false,
        idAlternativa: 17,
        idPregunta: 4,
        valorAlternativa: 4,
      },
      {
        contenido: '<p>Parcialmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 18,
        idPregunta: 4,
        valorAlternativa: 3,
      },
      {
        contenido: '<p>Desacuerdo</p>',
        correcta: false,
        idAlternativa: 19,
        idPregunta: 4,
        valorAlternativa: 1,
      },
      {
        contenido: '<p>Muy en desacuerdo</p>',
        correcta: false,
        idAlternativa: 20,
        idPregunta: 4,
        valorAlternativa: 0,
      },
    ],
  },

  {
    idPregunta: 5,
    idAreaConocimiento: 2,
    contenido:
      '<p>Continuamente buscamos nuevas oportunidades para satisfacer mejor las necesidades y  expectativas de nuestros clientes.</p>',
    nivel: 1,
    tipo: 1,
    alternativas: [
      {
        contenido: '<p>Totalmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 21,
        idPregunta: 5,
        valorAlternativa: 6,
      },
      {
        contenido: '<p>De acuerdo</p>',
        correcta: false,
        idAlternativa: 22,
        idPregunta: 5,
        valorAlternativa: 4,
      },
      {
        contenido: '<p>Parcialmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 23,
        idPregunta: 5,
        valorAlternativa: 3,
      },
      {
        contenido: '<p>Desacuerdo</p>',
        correcta: false,
        idAlternativa: 24,
        idPregunta: 5,
        valorAlternativa: 1,
      },
      {
        contenido: '<p>Muy en desacuerdo</p>',
        correcta: false,
        idAlternativa: 25,
        idPregunta: 5,
        valorAlternativa: 0,
      },
    ],
  },
  {
    idPregunta: 6,
    idAreaConocimiento: 2,
    contenido:
      '<p>La información del cliente y las pruebas de usuario se utilizan para refinar continuamente los productos y servicios digitales una vez que se han lanzado.</p>',
    nivel: 1,
    tipo: 1,
    alternativas: [
      {
        contenido: '<p>Totalmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 26,
        idPregunta: 6,
        valorAlternativa: 6,
      },
      {
        contenido: '<p>De acuerdo</p>',
        correcta: false,
        idAlternativa: 27,
        idPregunta: 6,
        valorAlternativa: 4,
      },
      {
        contenido: '<p>Parcialmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 28,
        idPregunta: 6,
        valorAlternativa: 3,
      },
      {
        contenido: '<p>Desacuerdo</p>',
        correcta: false,
        idAlternativa: 29,
        idPregunta: 6,
        valorAlternativa: 1,
      },
      {
        contenido: '<p>Muy en desacuerdo</p>',
        correcta: false,
        idAlternativa: 30,
        idPregunta: 6,
        valorAlternativa: 0,
      },
    ],
  },
  {
    idPregunta: 7,
    idAreaConocimiento: 2,
    contenido:
      '<p>Medimos la experiencia del cliente de forma continua y satisfacemos las necesidades y expectativas del cliente.</p>',
    nivel: 1,
    tipo: 1,
    alternativas: [
      {
        contenido: '<p>Totalmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 31,
        idPregunta: 7,
        valorAlternativa: 6,
      },
      {
        contenido: '<p>De acuerdo</p>',
        correcta: false,
        idAlternativa: 32,
        idPregunta: 7,
        valorAlternativa: 4,
      },
      {
        contenido: '<p>Parcialmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 33,
        idPregunta: 7,
        valorAlternativa: 3,
      },
      {
        contenido: '<p>Desacuerdo</p>',
        correcta: false,
        idAlternativa: 34,
        idPregunta: 7,
        valorAlternativa: 1,
      },
      {
        contenido: '<p>Muy en desacuerdo</p>',
        correcta: false,
        idAlternativa: 35,
        idPregunta: 7,
        valorAlternativa: 0,
      },
    ],
  },
  {
    idPregunta: 8,
    idAreaConocimiento: 3,
    contenido:
      '<p>Tenemos una cultura de innovación y colaboración en torno a los experimentos digitales, compartir los aprendizajes de experimentos fallidos se valora tanto como las ideas exitosas.</p>',
    nivel: 1,
    tipo: 1,
    alternativas: [
      {
        contenido: '<p>Totalmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 36,
        idPregunta: 8,
        valorAlternativa: 4,
      },
      {
        contenido: '<p>De acuerdo</p>',
        correcta: false,
        idAlternativa: 37,
        idPregunta: 8,
        valorAlternativa: 3,
      },
      {
        contenido: '<p>Parcialmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 38,
        idPregunta: 8,
        valorAlternativa: 2,
      },
      {
        contenido: '<p>Desacuerdo</p>',
        correcta: false,
        idAlternativa: 39,
        idPregunta: 8,
        valorAlternativa: 1,
      },
      {
        contenido: '<p>Muy en desacuerdo</p>',
        correcta: false,
        idAlternativa: 40,
        idPregunta: 8,
        valorAlternativa: 0,
      },
    ],
  },
  {
    idPregunta: 9,
    idAreaConocimiento: 3,
    contenido:
      '<p>A menudo establecemos equipos interdepartamentales para trabajar en colaboración en la iteración y desarrollo de productos digitales.</p>',
    nivel: 1,
    tipo: 1,
    alternativas: [
      {
        contenido: '<p>Totalmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 41,
        idPregunta: 9,
        valorAlternativa: 4,
      },
      {
        contenido: '<p>De acuerdo</p>',
        correcta: false,
        idAlternativa: 42,
        idPregunta: 9,
        valorAlternativa: 3,
      },
      {
        contenido: '<p>Parcialmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 43,
        idPregunta: 9,
        valorAlternativa: 2,
      },
      {
        contenido: '<p>Desacuerdo</p>',
        correcta: false,
        idAlternativa: 44,
        idPregunta: 9,
        valorAlternativa: 1,
      },
      {
        contenido: '<p>Muy en desacuerdo</p>',
        correcta: false,
        idAlternativa: 45,
        idPregunta: 9,
        valorAlternativa: 0,
      },
    ],
  },
  {
    idPregunta: 10,
    idAreaConocimiento: 3,
    contenido:
      '<p>Nos centramos en pequeñas iteraciones y mejoras de productos, en lugar de proyectos digitales a gran escala.</p>',
    nivel: 1,
    tipo: 1,
    alternativas: [
      {
        contenido: '<p>Totalmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 46,
        idPregunta: 10,
        valorAlternativa: 4,
      },
      {
        contenido: '<p>De acuerdo</p>',
        correcta: false,
        idAlternativa: 47,
        idPregunta: 10,
        valorAlternativa: 3,
      },
      {
        contenido: '<p>Parcialmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 48,
        idPregunta: 10,
        valorAlternativa: 2,
      },
      {
        contenido: '<p>Desacuerdo</p>',
        correcta: false,
        idAlternativa: 49,
        idPregunta: 10,
        valorAlternativa: 1,
      },
      {
        contenido: '<p>Muy en desacuerdo</p>',
        correcta: false,
        idAlternativa: 50,
        idPregunta: 10,
        valorAlternativa: 0,
      },
    ],
  },
  {
    idPregunta: 11,
    idAreaConocimiento: 3,
    contenido:
      '<p>Regularmente revisamos y reevaluamos nuestra dirección estratégica y hoja de ruta digital para asegurarnos de que esté alineada con las cambiantes necesidades del cliente y la dinámica del mercado.</p>',
    nivel: 1,
    tipo: 1,
    alternativas: [
      {
        contenido: '<p>Totalmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 51,
        idPregunta: 11,
        valorAlternativa: 4,
      },
      {
        contenido: '<p>De acuerdo</p>',
        correcta: false,
        idAlternativa: 52,
        idPregunta: 11,
        valorAlternativa: 3,
      },
      {
        contenido: '<p>Parcialmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 53,
        idPregunta: 11,
        valorAlternativa: 2,
      },
      {
        contenido: '<p>Desacuerdo</p>',
        correcta: false,
        idAlternativa: 54,
        idPregunta: 11,
        valorAlternativa: 1,
      },
      {
        contenido: '<p>Muy en desacuerdo</p>',
        correcta: false,
        idAlternativa: 55,
        idPregunta: 11,
        valorAlternativa: 0,
      },
    ],
  },
  {
    idPregunta: 12,
    idAreaConocimiento: 3,
    contenido:
      '<p>Durante la crisis del coronavirus, hemos podido pasar rápidamente a la resolución ágil de problemas.</p>',
    nivel: 1,
    tipo: 1,
    alternativas: [
      {
        contenido: '<p>Totalmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 56,
        idPregunta: 12,
        valorAlternativa: 4,
      },
      {
        contenido: '<p>De acuerdo</p>',
        correcta: false,
        idAlternativa: 57,
        idPregunta: 12,
        valorAlternativa: 3,
      },
      {
        contenido: '<p>Parcialmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 58,
        idPregunta: 12,
        valorAlternativa: 2,
      },
      {
        contenido: '<p>Desacuerdo</p>',
        correcta: false,
        idAlternativa: 59,
        idPregunta: 12,
        valorAlternativa: 1,
      },
      {
        contenido: '<p>Muy en desacuerdo</p>',
        correcta: false,
        idAlternativa: 60,
        idPregunta: 12,
        valorAlternativa: 0,
      },
    ],
  },
  {
    idPregunta: 13,
    idAreaConocimiento: 3,
    contenido:
      '<p>En tiempos de interrupción e incertidumbre, nos mantenemos enfocados en nuestros objetivos estratégicos e iniciativas de transformación digital.</p>',
    nivel: 1,
    tipo: 1,
    alternativas: [
      {
        contenido: '<p>Totalmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 56,
        idPregunta: 12,
        valorAlternativa: 4,
      },
      {
        contenido: '<p>De acuerdo</p>',
        correcta: false,
        idAlternativa: 57,
        idPregunta: 12,
        valorAlternativa: 3,
      },
      {
        contenido: '<p>Parcialmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 58,
        idPregunta: 12,
        valorAlternativa: 2,
      },
      {
        contenido: '<p>Desacuerdo</p>',
        correcta: false,
        idAlternativa: 59,
        idPregunta: 12,
        valorAlternativa: 1,
      },
      {
        contenido: '<p>Muy en desacuerdo</p>',
        correcta: false,
        idAlternativa: 60,
        idPregunta: 12,
        valorAlternativa: 0,
      },
    ],
  },
  {
    idPregunta: 14,
    idAreaConocimiento: 4,
    contenido:
      '<p>Tenemos las habilidades internas y la capacidad digital para respaldar las actividades comerciales habituales, así como proyectos más grandes.</p>',
    nivel: 1,
    tipo: 1,
    alternativas: [
      {
        contenido: '<p>Totalmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 61,
        idPregunta: 13,
        valorAlternativa: 4,
      },
      {
        contenido: '<p>De acuerdo</p>',
        correcta: false,
        idAlternativa: 62,
        idPregunta: 13,
        valorAlternativa: 3,
      },
      {
        contenido: '<p>Parcialmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 63,
        idPregunta: 13,
        valorAlternativa: 2,
      },
      {
        contenido: '<p>Desacuerdo</p>',
        correcta: false,
        idAlternativa: 64,
        idPregunta: 13,
        valorAlternativa: 1,
      },
      {
        contenido: '<p>Muy en desacuerdo</p>',
        correcta: false,
        idAlternativa: 65,
        idPregunta: 13,
        valorAlternativa: 0,
      },
    ],
  },
  {
    idPregunta: 15,
    idAreaConocimiento: 4,
    contenido:
      '<p> El valor de la inversión digital para lograr nuestros objetivos comerciales se entiende en toda la organización, incluido el C-suite.</p>',
    nivel: 1,
    tipo: 1,
    alternativas: [
      {
        contenido: '<p>Totalmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 66,
        idPregunta: 14,
        valorAlternativa: 5,
      },
      {
        contenido: '<p>De acuerdo</p>',
        correcta: false,
        idAlternativa: 67,
        idPregunta: 14,
        valorAlternativa: 3,
      },
      {
        contenido: '<p>Parcialmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 68,
        idPregunta: 14,
        valorAlternativa: 2,
      },
      {
        contenido: '<p>Desacuerdo</p>',
        correcta: false,
        idAlternativa: 69,
        idPregunta: 14,
        valorAlternativa: 1,
      },
      {
        contenido: '<p>Muy en desacuerdo</p>',
        correcta: false,
        idAlternativa: 70,
        idPregunta: 14,
        valorAlternativa: 0,
      },
    ],
  },
  {
    idPregunta: 16,
    idAreaConocimiento: 4,
    contenido:
      '<p>Buscamos activamente formas de aportar mayor flexibilidad, escala y eficiencia a los sistemas de back-end que impulsan las experiencias de los clientes frontend</p>',
    nivel: 1,
    tipo: 1,
    alternativas: [
      {
        contenido: '<p>Totalmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 71,
        idPregunta: 15,
        valorAlternativa: 5,
      },
      {
        contenido: '<p>De acuerdo</p>',
        correcta: false,
        idAlternativa: 72,
        idPregunta: 15,
        valorAlternativa: 3,
      },
      {
        contenido: '<p>Parcialmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 73,
        idPregunta: 15,
        valorAlternativa: 2,
      },
      {
        contenido: '<p>Desacuerdo</p>',
        correcta: false,
        idAlternativa: 74,
        idPregunta: 15,
        valorAlternativa: 1,
      },
      {
        contenido: '<p>Muy en desacuerdo</p>',
        correcta: false,
        idAlternativa: 75,
        idPregunta: 15,
        valorAlternativa: 0,
      },
    ],
  },
  {
    idPregunta: 17,
    idAreaConocimiento: 4,
    contenido:
      '<p>Usamos y podemos agregar fácilmente servicios, integraciones y API de terceros para mejorar y respaldar la experiencia del cliente.</p>',
    nivel: 1,
    tipo: 1,
    alternativas: [
      {
        contenido: '<p>Totalmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 76,
        idPregunta: 16,
        valorAlternativa: 5,
      },
      {
        contenido: '<p>De acuerdo</p>',
        correcta: false,
        idAlternativa: 77,
        idPregunta: 16,
        valorAlternativa: 3,
      },
      {
        contenido: '<p>Parcialmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 78,
        idPregunta: 16,
        valorAlternativa: 2,
      },
      {
        contenido: '<p>Desacuerdo</p>',
        correcta: false,
        idAlternativa: 79,
        idPregunta: 16,
        valorAlternativa: 1,
      },
      {
        contenido: '<p>Muy en desacuerdo</p>',
        correcta: false,
        idAlternativa: 80,
        idPregunta: 16,
        valorAlternativa: 0,
      },
    ],
  },
  {
    idPregunta: 18,
    idAreaConocimiento: 4,
    contenido:
      '<p>Podemos implementar mejoras y cambios de productos digitales relativamente rápido y sin problemas.</p>',
    nivel: 1,
    tipo: 1,
    alternativas: [
      {
        contenido: '<p>Totalmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 81,
        idPregunta: 17,
        valorAlternativa: 5,
      },
      {
        contenido: '<p>De acuerdo</p>',
        correcta: false,
        idAlternativa: 82,
        idPregunta: 17,
        valorAlternativa: 3,
      },
      {
        contenido: '<p>Parcialmente de acuerdo</p>',
        correcta: false,
        idAlternativa: 83,
        idPregunta: 17,
        valorAlternativa: 2,
      },
      {
        contenido: '<p>Desacuerdo</p>',
        correcta: false,
        idAlternativa: 84,
        idPregunta: 17,
        valorAlternativa: 1,
      },
      {
        contenido: '<p>Muy en desacuerdo</p>',
        correcta: false,
        idAlternativa: 85,
        idPregunta: 17,
        valorAlternativa: 0,
      },
    ],
  },

];
