import React, {useState} from "react";
// import { Component } from "react";
import Modal from "../Modal";
// import { makeStyles } from '@material-ui/core/styles';
// import Modal from '@material-ui/core/Modal';
// import Icon from '@material-ui/core/Icon';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    overflowY: "scroll",
  };
}

const useStyles = {
  paper: {
    position: "absolute",
    width: "80%",
    height: "80%",
    backgroundColor: "#1B2631",
    border: "2px solid #000",
    textAlign: "justify",
    padding: "2%",
  },
};


export default function SimpleModal() {

  // const classes = useStyles;
  // getModalStyle is not a pure function, we roll the style only on the first render
  
  const [open, setOpen] = useState(false);

  const handleToggel = () => {
    setOpen(!open);
  };

  const body = (
    <div  style={{height: "500px"}}>
      <div className="row align-end">
        <div style={{position: "absolute", right: '10px'}}>
          <button
            onClick={handleToggel}
            style={{ border: "none", backgroundColor: "#1b2631" }}
          >
            <div style={{ color: "white" }}>close</div>
          </button>
        </div>
      </div>
      <h2 id="simple-modal-title" style={{ textAlign: "center" }}>
        Políticas de Privacidad y uso de datos
      </h2>
      <br></br>
      <div
        style={{ textAlign: "justify", overflowY:"scroll", height:"85%"}}
      >
        {/* <h2 style={{ textAlign: 'center' }}>
					Políticas de Privacidad y uso de datos
				</h2> */}
        <h4>1. INTRODUCCIÓN.</h4>
        TECSUP asegura la máxima reserva y protección de aquellos datos de
        carácter personal que los usuarios del sitio en Internet de su propiedad
        («Usuarios»), ubicado en la dirección electrónica
        http://www.tecsup-aqp.edu.pe/asociacion/, ingresen al sitio con el fin
        de utilizar los servicios de éste.
        <h4>2. OBJETIVO Y FINALIDAD.</h4>
        En TECSUP somos conscientes de la elevada consideración que tiene la
        privacidad de nuestros clientes y de todas aquellas personas que se
        interesan por nuestros servicios; en definitiva, de nuestros usuarios.
        Siendo consecuentes con esta consideración tenemos el compromiso de
        respetar su privacidad y proteger la confidencialidad de su información
        privada y datos personales. Y es por ello que el objetivo de esta
        política de privacidad es dar a conocer a los usuarios de TECSUP a
        través de Internet la manera en que se recogen, se tratan y se protegen
        los datos personales que son introducidos por ellos en los distintos
        formularios de recogida de datos que se encuentran en el sitio Web de
        TECSUP a su disposición. La información personal privada no incluye
        información que está disponible a través de fuentes accesibles al
        público. Se entiende por tales los medios de comunicación electrónica,
        óptica y de otra tecnología concebidos para facilitar información al
        público y abiertos a la consulta general, las guías telefónicas, los
        diarios y revistas, los medios de comunicación social, las listas
        profesionales, los repertorios de jurisprudencia anonimizados, los
        Registros Públicos administrados por la Superintendencia Nacional de
        Registros Públicos así como todo otro registro o banco de datos
        calificado como público conforme a ley, las entidades de la
        Administración Pública en relación a la información que deba ser
        entregada en aplicación de la Ley Nº 27806, Ley de Transparencia y
        Acceso a la Información Pública.
        <h4>3. LEGISLACIÓN.</h4>
        De acuerdo con la Ley N° 29733 – Ley de Protección de Datos Personales y
        su Reglamento, aprobado por el Decreto Supremo No. 003-2013-JUS, se
        entiende por datos personales toda información sobre una persona natural
        que la identifica o hace identificable a través de medios que pueden ser
        razonablemente utilizados. Y por Tratamiento de Datos Personales
        cualquier operación o procedimiento técnico, automatizado o no, que
        permite la recopilación, registro, organización, almacenamiento,
        conservación, elaboración, modificación, extracción, consulta,
        utilización, bloqueo, supresión, comunicación por transferencia o por
        difusión o cualquier otra forma de procesamiento que facilite el acceso,
        correlación o interconexión de los datos personales. TECSUP desarrolla
        su política de tratamiento de datos personales en atención a los
        principios rectores establecidos en la Ley Nº 29733 de Protección de
        Datos Personales y por lo tanto:
        <br></br>
        <br></br>
        <p>
          – Conforme al principio de legalidad, se rechaza la recopilación de
          los datos personales de nuestros usuarios por medios fraudulentos,
          desleales o ilícitos.
        </p>
        <p>
          – Conforme al principio de consentimiento, en el tratamiento de los
          datos personales de nuestros usuarios mediará su consentimiento.
        </p>
        <p>
          – Los datos personales de nuestros usuarios se recopilarán para una
          finalidad determinada, explícita y lícita, y no se extenderá a otra
          finalidad que no haya sido la establecida de manera inequívoca como
          tal al momento de su recopilación, excluyendo los casos de actividades
          de valor histórico, estadístico o científico cuando se utilice un
          procedimiento de disociación o anonimización.
        </p>
        <p>
          – Todo tratamiento de datos personales de nuestros usuarios será
          adecuado, relevante y no excesivo a la finalidad para la que estos
          hubiesen sido recopilados.
        </p>
        <p>
          – Los datos personales que vayan a ser tratados serán veraces, exactos
          y, en la medida de lo posible, actualizados, necesarios, pertinentes y
          adecuados respecto de la finalidad para la que fueron recopilados. Se
          conservarán de forma tal que se garantice su seguridad y solo por el
          tiempo necesario para cumplir con la finalidad del tratamiento.
        </p>
        <p>
          – TECSUP y, en su caso, los encargados de tratamiento, adoptan las
          medidas técnicas, organizativas y legales necesarias para garantizar
          la seguridad y confidencialidad de los datos personales. TECSUP cuenta
          con las medidas de seguridad apropiadas y acordes con el tratamiento
          que se vaya a efectuar y con la categoría de datos personales de que
          se trate.
        </p>
        <p>
          – TECSUP informa a sus usuarios la existencia de la vía administrativa
          para hacer valer sus derechos a ejercitar ante la Autoridad Nacional
          de Protección de Datos o jurisdiccionales ante el Poder Judicial a los
          efectos de la correspondiente acción de habeas data.
        </p>
        <p>
          – TECSUP garantiza el nivel adecuado de protección de los datos
          personales de nuestros usuarios para el flujo transfronterizo de datos
          personales, con un mínimo de protección equiparable a lo previsto por
          esta Ley o por los estándares internacionales en la materia.
        </p>
        <h4>4. INFORMACIÓN</h4>
        <p>
          I. El usuario queda informado de que sus datos personales, a los que
          TECSUP tenga acceso como consecuencia de consultas sobre servicios y
          su contratación se incorporan al correspondiente “Banco de Datos
          Personales”, en tanto conjunto organizado de datos personales de
          nuestros usuarios, creado por TECSUP y registrado en el Registro
          Nacional de Protección de Datos Personales competencia de la Autoridad
          Nacional de Protección de datos Personales, autorizando el usuario a
          TECSUP al tratamiento de los que sean necesarios para su utilización
          en relación con el desarrollo de la operación o servicio a que se
          refieran.
        </p>
        <p>
          II. El usuario autoriza expresamente a TECSUP el uso de sus datos en
          la oferta y contratación productos y servicios, así como para el
          desarrollo de acciones comerciales, sean de carácter general o
          adaptadas a sus características personales.
        </p>
        <p>
          III. Dichas acciones comerciales podrán ser realizadas por correo
          electrónico u otro medio de comunicación electrónica equivalente, por
          medio de informaciones publicitarias o promocionales, de carácter
          general o adaptadas a su perfil comercial, sobre los servicios
          ofrecidos y comercializados por TECSUP.
        </p>
        <p>
          IV. En dichas acciones comerciales el usuario será adecuadamente
          informado respecto del carácter obligatorio o facultativo de la
          respuesta a las cuestiones que le sean planteadas en los formularios
          respectivos, y de las consecuencias de la negativa a facilitar los
          datos que se le soliciten.
        </p>
        <p>
          V. Mediante el envío de cualquiera de los formularios que figuren en
          el portal, el usuario presta de forma libre e inequívoca su
          consentimiento al régimen del tratamiento de los datos de carácter
          personal y de la dirección de correo electrónico que se describa en el
          aviso sobre dicho régimen habilitado en el formulario correspondiente.
        </p>
        <p>
          VI. En el caso de que entre las finalidades a que TECSUP destine los
          datos se encuentre la de tratar los mismos con fines publicitarios o
          promocionales, en el momento mismo de rellenar el correspondiente
          formulario el usuario podrá oponerse a la recepción de informaciones
          publicitarias o promocionales haciendo click en la casilla a tal
          efecto habilitada en aquél.
        </p>
        <p>
          VII. Los datos de carácter personal, incluida la dirección de correo
          electrónico, que el usuario le proporcione al rellenar y enviar los
          formularios que figuran en el portal con
          dominiohttp://www.tecsup-aqp.edu.pe/ serán incorporados a los bancos
          de datos de TECSUP para que éste pueda efectuar el tratamiento,
          automatizado o no, de tales datos, así como de los que puedan
          generarse por causa de la correspondiente relación contractual que, en
          su caso, el usuario concierte con TECSUP.
        </p>
        <p>
          VIII. Mediante el envío de cualquiera de los formularios que figuren
          en el portal, el usuario presta su inequívoco consentimiento al
          régimen del tratamiento de los datos de carácter personal y de la
          dirección de correo electrónico que se describa en el aviso sobre
          dicho régimen habilitado en el formulario correspondiente.
        </p>
        <h4> 5. CALIDAD DE LOS DATOS PERSONALES</h4>
        <p>
          I. Los datos personales solicitados a los Usuarios de Internet en los
          diferentes formularios del sitio Web, son datos básicos de contacto y
          son adecuados, pertinentes y no excesivos en relación con la finalidad
          para los que se recogen.
        </p>
        <p>
          II. Los datos personales solicitados a los usuarios de TECSUP
          proporciona a los Usuarios los recursos técnicos adecuados para que
          tomen conocimiento de la presente Política de Privacidad y de
          cualquier otra información que pueda ser relevante, constituyendo el
          ingreso de Datos Personales o información confidencial en el sitio Web
          la manifestación expresa de su consentimiento a la presente Política
          de Privacidad. Los datos personales a los que TECSUP tendrá acceso
          serán aquellos que el usuario facilite voluntariamente. Es por ello
          que acogemos como nuestra responsabilidad hacer que nuestros usuarios
          sepan que para darse de alta y registrarse en algunos productos o
          servicios que ofrecemos a través de la Web, se le solicitarán datos de
          carácter personal. De ese modo, en los casos y respecto de los datos
          en los que así se indique expresamente al usuario, si no facilita los
          mismos, no podrá acceder ni utilizar los referidos servicios y
          contenidos.
        </p>
        <p>
          III. Su información personal sólo podrá ser conocida y manejada por el
          personal de TECSUP que necesite conocer dicha información para
          suministrarle la información correspondiente a los servicios de la
          asociación.
        </p>
        <p>
          IV. Los datos personales facilitados por los Usuarios de Internet
          tienen que ser exactos y correctos de forma que respondan con
          veracidad a su situación actual. En caso contrario estos datos serán
          cancelados.
        </p>
        <p>
          V. Los datos personales facilitados por los Usuarios de Internet serán
          tratados de forma leal y lícita.
        </p>
        <p>
          VI. Los datos personales facilitados por los Usuarios de Internet son
          recogidos para el cumplimiento de las finalidades expuestas en este
          documento y no se usarán para otras finalidades incompatibles con las
          especificadas.
        </p>
        <p>
          VII. Los datos personales serán cancelados cuando hayan dejado de ser
          necesarios para las finalidades para las cuales han sido recogidos.
          Sin embargo, se conservarán durante el tiempo en qué pueda exigirse
          algún tipo de responsabilidad a TECSUP derivada de esta relación con
          los Usuarios de Internet.
        </p>
        <h4>6. FINALIDAD DE LOS TRATAMIENTOS DE LA INFORMACIÓN PERSONAL.</h4>
        <p>
          I. Las finalidades de tratamiento de los datos personales que los
          Usuarios de Internet introducen en los diferentes formularios del
          sitio Web son para poder realizar la gestión comercial, de
          comunicación, marketing, administrativa y soporte a usuarios
          relacionada con los servicios que ofrece TECSUP. Así como contestar a
          las consultas y otro tipo de información que soliciten los Usuarios de
          Internet y poder facilitar a posteriori cualquier información que
          TECSUP crea que puede ser de su interés.
        </p>
        <p>
          II. TECSUP puede reunir, analizar y utilizar la información que
          obtiene de los Usuarios, como un todo colectivo. Así, puede determinar
          qué áreas son las preferidas o los servicios más utilizados. Dicha
          información, que sólo se usa en forma anónima y agregada, de manera de
          poder determinar cómo hacer el Sitio cada vez mejor para sus Usuarios.
        </p>
        <p>
          III. Cuando los Usuarios ingresan sus Datos Personales, el Sitio abre
          una página personal para cada Usuario en la cual almacena sus
          antecedentes, utilizamos dicha información con el objeto de poder
          identificarlo plenamente al utilizar nuestros servicios, permitirle
          una experiencia más personalizada y poder realizar envíos de
          información vía alertas o correo electrónico. Los Usuarios pueden
          ingresar (con su nombre de usuario y clave) en cada oportunidad que
          requiera revisar, actualizar y/o modificar sus Datos Personales
          contenidos en ella. (SE PROPONE QUE LA WEB TENGA ESTA OPCIÓN).
        </p>
        <p>
          IV. TECSUP será responsable de la Base de Datos y de los Datos
          Personales contenidos en cada página personal. Con el objeto de evitar
          la pérdida, mal uso, alteración, acceso no autorizado y robo de los
          Datos Personales o Información Confidencial facilitados por los
          Usuarios, TECSUP ha adoptado los niveles de seguridad y de protección
          de Datos Personales legalmente requeridos, y ha instalado todos los
          medios y medidas técnicas a su alcance.
        </p>
        <p>
          V. TECSUP respeta la privacidad de sus usuarios, no compartiremos su
          información con terceros si usted no desea expresamente que lo
          hagamos.
        </p>
        <h4>7. TRATAMIENTO DE DATOS PERSONALES Y CONSENTIMIENTO</h4>
        <p>
          I. Los datos personales facilitados por los Usuarios de Internet se
          almacenarán en los bancos de datos que forman parte del sistema de
          información de TECSUP y serán tratados para poder llevar a cabo las
          finalidades expuestas anteriormente.
        </p>
        <p>
          II. Los bancos de datos que contienen datos personales están inscritos
          en el Registro de Protección de Datos de la Autoridad Peruana.
        </p>
        <p>
          III. El Usuario de Internet que facilita datos de personales autoriza
          su tratamiento para poder llevar a cabo las finalidades expuestas en
          el punto anterior.
        </p>
        <h4>8. COMUNICACIÓN DE DATOS PERSONALES</h4>
        <p>
          I. Los datos personales serán comunicados por transferencia entre las
          empresas y filiales que forman parte de TECSUP para poder gestionar
          adecuadamente los productos y servicios solicitados por los Usuarios
          de Internet y las gestiones que se deriven posteriormente.
        </p>
        Estos datos personales también pueden ser comunicados a las entidades
        administrativas, autoridades judiciales y/o policiales, siempre y cuando
        esté establecido por Ley.
      </div>

      <br></br>
    </div>
  );

  return (
    <>
      <a onClick={handleToggel} style={{ color: "#0d67fc", cursor: "pointer" }}>
        Políticas de Privacidad
      </a>
      <Modal open={open}>
        <div>{body}</div>
      </Modal>
    </>
  );
}
