// const { default: styled } = require("styled-components");

import styled from 'styled-components';


export const Container = styled.div`
  position: relative;
`;






