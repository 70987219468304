import React from "react";
import { ChatStyle } from "./styles";
import "./ChatStyles.css";

const ChatBot = () => {
  return (
    <div>
      <df-messenger
        intent="WELCOME"
        chat-title="Transformación Digital"
        agent-id="8ee52ea2-d0d3-4fc6-93e5-8b42ef01a90e"
        language-code="es"
        chat-icon="https://cdn.discordapp.com/attachments/689530664919367861/831922720426033182/Logotipo_Moda_Redondo_en_Ceniza_con_Borde_2.png"
      ></df-messenger>
    </div>
  );
};

export default ChatBot;
