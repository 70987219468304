import React, { Component } from "react";
import ContainerModal from "./styles";

class Modal extends Component {
  render() {
    const { children, open } = this.props;
    return (
      <div>
        {open && (
          <ContainerModal>
            <div className="window-modal">
              <div>{children}</div>
            </div>
          </ContainerModal>
        )}
      </div>
    );
  }
}

export default Modal;
